import { Button, Checkbox } from '@momentum-ui/react'
import { navigate } from 'gatsby'
import isEqual from 'lodash.isequal'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  NAME_VALIDATOR,
  DESCRIPTION_VALIDATOR,
  URL_VALIDATOR,
} from '../applicationValidators'
import { INTEGRATION_ACTION } from '../../../constants/metrics'
import { IIntegration } from '../../../interfaces/myAppInterfaces'
import httpService from '../../../services/httpService'
import { logError } from '../../../services/loggerService'
import MetricsService from '../../../services/metricsService'
import { retrieveToken, retrieveFeatureFlags } from '../../../state/auth'
import { AppState } from '../../../state/store'
import Dialog from '../../Dialog'
import ErrorAlert from '../../ErrorAlert'
import Link from '../../Link'
import ActionInput from '../ActionInput'
import EditButtons from '../EditButtons'
import { getBffBaseUrl } from '../../../services/configService'
import { getCIUrls } from '../../../utils/auth.utils'
import {
  dateFormatter,
  hasValidFeatureFlag,
} from '../../../utils/general.utils'
import { APPLICATION_SUBMITTED } from '../../../constants/applications'
import { getAppScopes } from '../../../templates/myApps/config'

interface IIntegrationProps {
  integration?: IIntegration
  hasCurrentApplications?: boolean
  updateName?: Function
}

const Integration: React.FC<IIntegrationProps> = ({
  integration,
  hasCurrentApplications,
  updateName,
}) => {
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()

  const [clientSecret, setClientSecret] = useState<any>(integration?.secret)
  const token = useSelector((state: AppState) => retrieveToken(state))
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [acceptedToS, setacceptedToS] = useState<boolean>(false)
  const existingIntegration = !!integration
  const [hasError, setHasError] = useState<boolean>(false)
  const [scopeTouched, setScopeTouched] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<object>({})
  const [formInitial, setFormInitial] = useState<any>({
    redirectionURIs: integration?.redirectionURIs || [''],
    name: integration?.name || '',
    description: integration?.description || '',
    scopes: integration?.scopes || [],
    submissionStatus: integration?.submissionStatus || '',
    submissionDate: integration?.submissionDate || '',
    isSubmissionFeatured: integration?.isFeatured || false,
  })

  const [formDiff, setFormDiff] = useState<any>({
    redirectionURIs: formInitial.redirectionURIs,
    name: formInitial.name,
    description: formInitial.description,
    scopes: formInitial.scopes,
  })
  const [dialogState, setDialogState] = useState<any>({
    isShowing: false,
    type: '',
  })

  const hasEmptyScopeError = scopeTouched && !formDiff.scopes.length

  const userFeatureFlags = useSelector((state: AppState) =>
    retrieveFeatureFlags(state)
  )

  const hasCloudContactFeatureFlag = hasValidFeatureFlag(
    userFeatureFlags,
    `${process.env.GATSBY_CLOUD_CONTACT_NEEDS_FEATURE_FLAG}`,
    `${process.env.GATSBY_CLOUD_CONTACT_FEATURE_FLAG}`
  )

  const hasServiceAppFeatureFlag = hasValidFeatureFlag(
    userFeatureFlags,
    `${process.env.GATSBY_SERVICE_APP_NEEDS_FEATURE_FLAG}`,
    `${process.env.GATSBY_SERVICE_APP_FEATURE_FLAG}`
  )

  const scopes = getAppScopes(
    hasCloudContactFeatureFlag,
    hasServiceAppFeatureFlag
  )

  const canShowSubmitToApphub =
    existingIntegration &&
    integration?.submissionStatus?.toLowerCase() !== APPLICATION_SUBMITTED

  const { idBrokerUrl } = getCIUrls()
  const httpConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      idBrokerHost: idBrokerUrl,
    },
  }

  const isDirty = (integrationField: string): boolean => {
    if (integrationField === 'redirectionURIs') {
      return !isEqual(
        formInitial[integrationField],
        formDiff[integrationField].filter((uri: string) => {
          return uri.length
        })
      )
    } else {
      return !isEqual(formInitial[integrationField], formDiff[integrationField])
    }
  }

  const handleFormSubmit = async (integrationField: string): Promise<void> => {
    setIsSubmitting(true)

    let integrationJson: any = {}

    try {
      if (integrationField && !!integration) {
        integrationJson[integrationField] = formDiff[integrationField]
        await httpService.patch(
          `${getBffBaseUrl()}/v1/integrations/${integration.id}`,
          integrationJson,
          httpConfig
        )
        setIsSubmitting(false)

        metricsService.track(INTEGRATION_ACTION, {
          type: 'Update',
        })

        if (integrationField === 'name' && updateName) {
          updateName(formDiff.name)
        }
        setFormInitial({ ...formInitial, ...integrationJson })
      } else {
        integrationJson = { ...formDiff }
        const resp = await httpService.post(
          `${getBffBaseUrl()}/v1/integrations`,
          integrationJson,
          httpConfig
        )
        setIsSubmitting(false)

        metricsService.track(INTEGRATION_ACTION, {
          type: 'Create',
          scopes: integrationJson.scopes,
        })

        navigate(`/my-apps/${resp.data.id}`, {
          state: {
            integrationName: integrationJson.name,
            clientId: resp.data.id,
            clientSecret: resp.data.secret,
          },
        })
      }
    } catch (err) {
      setIsSubmitting(false)
      setHasError(true)
      logError(`An error has occurred while adding the integration ${err}`)
    }
  }

  const handleCreateCancel = (): void => {
    if (hasCurrentApplications) {
      navigate('/my-apps')
    } else {
      const noApplicationsPath = hasServiceAppFeatureFlag ? '/my-apps/new' : '/'
      navigate(noApplicationsPath)
    }
  }

  const handleEditCancel = (integrationField: string): void => {
    const cleanDiff = { ...formDiff }
    cleanDiff[integrationField] = formInitial[integrationField]
    setFormDiff({
      ...cleanDiff,
    })
    handleValidationState(false, integrationField)
  }

  const deleteIntegration = async (): Promise<void> => {
    try {
      setIsSubmitting(true)
      await httpService.delete(
        `${getBffBaseUrl()}/v1/integrations/${integration!.id}`,
        httpConfig
      )

      metricsService.track(INTEGRATION_ACTION, {
        type: 'Delete',
      })

      setIsSubmitting(false)
      navigate('/my-apps')
    } catch (err) {
      setIsSubmitting(false)
      setHasError(true)
      logError(`An error has occurred while deleting the integration ${err}`)
    }
  }

  const regenerateSecret = async (): Promise<void> => {
    try {
      const secret = await httpService.patch(
        `${getBffBaseUrl()}/v1/integrations/${integration?.id}/reset`,
        {},
        httpConfig
      )

      metricsService.track(INTEGRATION_ACTION, {
        type: 'Reset Client Secret',
      })

      setClientSecret(secret.data.secret)
    } catch (err) {
      setHasError(true)
      logError(
        `An error has occurred while regenerating the client secret ${err}`
      )
    }
  }

  const renderDialog = (): JSX.Element => {
    const closeDialog = (): void => {
      setDialogState({ isShowing: false, type: '' })
    }
    const isDelete = dialogState?.type === 'delete'
    const isModified = isDelete
      ? t('integrations.deleteIntegrationDialogText')
      : t('integrations.resetClientSecretDialogText')
    return (
      <Dialog
        dialogText={isModified}
        onSuccess={(): void => {
          if (isDelete) {
            deleteIntegration()
          } else {
            regenerateSecret()
          }
          closeDialog()
        }}
        onClose={closeDialog}
        showDialog={dialogState?.isShowing}
      />
    )
  }

  const getAuthorizationUrl = (): string => {
    const scopes =
      integration!.scopes && encodeURIComponent(integration!.scopes.join(' '))
    const redirectURI =
      integration!.redirectionURIs && integration!.redirectionURIs.length
        ? encodeURIComponent(integration!.redirectionURIs[0])
        : 'set_redirect_uri_here'

    return `${process.env.INTEGRATION_AUTH_URL}/v1/authorize?client_id=${integration?.id}&response_type=code&redirect_uri=${redirectURI}&scope=${scopes}&state=set_state_here`
  }

  const removeRedirectUri = (idx: number): void => {
    const newUris = formDiff.redirectionURIs.slice(0)
    newUris.splice(idx, 1)
    setFormDiff({ ...formDiff, redirectionURIs: newUris })
  }

  const addUri = (): void => {
    setFormDiff({
      ...formDiff,
      redirectionURIs: [...formDiff.redirectionURIs, ''],
    })
  }

  const handleToSLabelInteraction = (e: any): void => {
    // enter, space or clicking on label should change the checkbox
    if (e.key === ' ' || e.key === 'Enter' || !e.key) {
      setacceptedToS(!acceptedToS)
    }
  }

  const handleValidationState = (isInvalid: boolean, field: string): void => {
    setValidationErrors({ ...validationErrors, ...{ [field]: isInvalid } })
  }

  const disableFormSubmit =
    !acceptedToS ||
    Object.values(validationErrors).includes(true) ||
    hasEmptyScopeError ||
    (Object.values(formDiff) as []).filter((val: any) => val.length).length <
      Object.keys(formDiff).length

  return (
    <div className="application-form-container application-form-heading">
      {existingIntegration &&
        integration?.submissionStatus?.toLowerCase() ===
          APPLICATION_SUBMITTED && (
          <div className="form-row" data-cy="submission-status-section">
            <div className="columns medium-3">
              <label>
                <h3>{t('integrations.webexAppHub')}</h3>
              </label>
              <p>{t('integrations.webexAppHubLabel')}</p>
            </div>
            <div className="columns medium-6 medium-offset-1">
              <label>
                <span>{t('integrations.submissionStatus')}</span>
              </label>
              <p
                className="submissionStatusDate"
                data-testid="submission-status"
              >
                {t('apphubSubmission.submittedOn')}{' '}
                {dateFormatter(
                  (integration?.submissionDate as unknown) as number
                )}
              </p>
              <div>
                <label>
                  <span>{t('integrations.featured')}</span>
                </label>
                <p>
                  {integration?.isFeatured === true
                    ? t('integrations.isSubmissionFeatured.true')
                    : t('integrations.isSubmissionFeatured.false')}
                </p>
              </div>
            </div>
            <div className="columns medium-2" />
          </div>
        )}
      {existingIntegration && (
        <>
          <div className="form-row">
            <div className="columns medium-3">
              <h3>{t('integrations.oauthSettings')}</h3>
            </div>

            <div className="columns medium-6 medium-offset-1">
              <label htmlFor="client-id">
                <span>{t('integrations.clientId')}</span>
              </label>
              <ActionInput
                name="clientId"
                htmlId="client-id"
                value={integration?.id}
                readOnly
                copyable
                data-cy="client-id"
              />
              <div className="client-secret-container">
                <label htmlFor="client-secret">
                  <span>{t('integrations.clientSecret')}</span>
                </label>
                {clientSecret ? (
                  <ActionInput
                    name="clientSecret"
                    htmlId="client-secret"
                    value={clientSecret}
                    readOnly
                    copyable
                  />
                ) : (
                  <button
                    className="inline-text-btn"
                    onClick={(): void =>
                      setDialogState({ isShowing: true, type: 'secret' })
                    }
                    data-cy="reset-client-secret-btn"
                  >
                    {t('integrations.regenerateClientSecret')}
                  </button>
                )}
              </div>
              <label htmlFor="oauth-url">
                <span>{t('integrations.oauthUrl')}</span>
                <p>{t('integrations.oauthUrlLabel')}</p>
              </label>
              <ActionInput
                className={'oauth-url'}
                name="oauthUrl"
                htmlId="oauth-url"
                value={getAuthorizationUrl()}
                readOnly
                textArea
                rows={7}
              />
            </div>
            <div className="columns medium-2" />
          </div>
          {canShowSubmitToApphub ? (
            <div className="form-row">
              <div className="columns medium-3">
                <label htmlFor="integration-submitToApphub">
                  <h3>{t('integrations.submitToApphub')}</h3>
                  <span>*</span>
                </label>
                <p>{t('integrations.submitToApphubLabel')}</p>
              </div>
              <div className="columns medium-6 medium-offset-1">
                <Link
                  to={`/my-apps/${integration!.id}/submit`}
                  state={{ application: integration }}
                  className={`md-button md-button--blue-outline submit-button`}
                  id="submit-to-apphub"
                >
                  {t('integrations.submitToApphub')}
                </Link>
                <div className="apphub-tip">
                  <p>
                    {t('integrations.submitToApphubTip1')}
                    <Link
                      to="/documentation/app-submission-process"
                      className={`link`}
                      id="apphub-guideline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('integrations.apphubGuideline')}
                    </Link>
                    {t('integrations.submitToApphubTip2')}
                  </p>
                </div>
              </div>
              <div className="columns medium-2" />
            </div>
          ) : null}
        </>
      )}
      <div className="form-row">
        <div className="columns medium-3">
          <label htmlFor="integration-name">
            <h3>{t('integrations.integrationName')}</h3>
            <span>*</span>
          </label>
          <p>{t('integrations.integrationNameLabel')}</p>
        </div>
        <div className="columns medium-6 medium-offset-1">
          <ActionInput
            name="integrationName"
            htmlId="integration-name"
            placeholder={t('integrations.integrationNamePlaceholder')}
            value={formDiff.name}
            showEditButton={existingIntegration}
            onChange={(input: Event): void => {
              setFormDiff({
                ...formDiff,
                name: (input.target as HTMLInputElement).value,
              })
            }}
            handleSave={async (): Promise<void> => {
              await handleFormSubmit('name')
            }}
            handleCancel={(): void => {
              handleEditCancel('name')
            }}
            disableSave={!isDirty('name')}
            isLoading={isSubmitting}
            validation={NAME_VALIDATOR}
            handleValidationState={handleValidationState}
          />
        </div>
        <div className="columns medium-2" />
      </div>
      <div className="form-row">
        <div className="columns medium-3">
          <label htmlFor="integration-description">
            <h3>{t('integrations.description')}</h3>
            <span>*</span>
          </label>
          <p>{t('integrations.descriptionLabel')}</p>
        </div>
        <div className="columns medium-6 medium-offset-1">
          <ActionInput
            htmlId="integration-description"
            name="integrationDescription"
            value={formDiff.description}
            rows={10}
            textArea
            showEditButton={existingIntegration}
            onChange={(input: Event): void => {
              setFormDiff({
                ...formDiff,
                description: (input.target as HTMLInputElement).value,
              })
            }}
            handleSave={async (): Promise<void> => {
              await handleFormSubmit('description')
            }}
            handleCancel={(): void => {
              handleEditCancel('description')
            }}
            disableSave={!isDirty('description')}
            isLoading={isSubmitting}
            validation={DESCRIPTION_VALIDATOR}
            handleValidationState={handleValidationState}
          />
        </div>
        <div className="columns medium-2" />
      </div>
      <div className="form-row">
        <div className="columns medium-3">
          <label htmlFor="integration-redirect">
            <h3>{t('integrations.redirectUris')}</h3>
            <span>*</span>
          </label>
          <p>{t('integrations.redirectUrisLabel')}</p>
        </div>
        <div className="columns medium-6 medium-offset-1">
          <ActionInput
            name="integrationRedirect"
            htmlId="integration-redirect"
            placeholder={t('integrations.redirectUrisPlaceholder')}
            value={formDiff.redirectionURIs}
            showEditButton={existingIntegration}
            onChange={(input: Event, idx: any): void => {
              const urisCopy = formDiff.redirectionURIs.slice(0)
              urisCopy[idx] = (input.target as HTMLInputElement).value
              setFormDiff({
                ...formDiff,
                redirectionURIs: urisCopy,
              })
            }}
            handleSave={async (): Promise<void> => {
              await handleFormSubmit('redirectionURIs')
            }}
            handleCancel={(): void => {
              handleEditCancel('redirectionURIs')
            }}
            disableSave={!isDirty('redirectionURIs')}
            isLoading={isSubmitting}
            onRemoveInput={removeRedirectUri}
            onAddInput={addUri}
            addInputButtonText={t('integrations.addUri')}
            validation={URL_VALIDATOR}
            handleValidationState={handleValidationState}
          />
        </div>
        <div className="columns medium-2" />
      </div>
      <div className="form-row">
        <div className="columns medium-3">
          <h3>{t('integrations.scopes')}</h3>
          <span>*</span>
          <p>{t('integrations.scopesLabel')}</p>
        </div>
        <div className="columns medium-6 medium-offset-1">
          {scopes.map((scope: string, id) => {
            return (
              <Checkbox
                value={scope}
                key={id}
                label={scope}
                htmlId={scope}
                checked={formDiff.scopes.includes(scope)}
                onClick={(): void => {
                  const scopesArr = [...formDiff.scopes]
                  const index = scopesArr.indexOf(scope)
                  if (index > -1) {
                    scopesArr.splice(index, 1)
                  } else {
                    scopesArr.push(scope)
                  }
                  setFormDiff({
                    ...formDiff,
                    scopes: scopesArr,
                  })
                  setScopeTouched(true)
                }}
                data-cy={`scope-${id}`}
              />
            )
          })}
          {hasEmptyScopeError && (
            <div className="md-input-container md-error">
              <div className="md-input__messages">
                <div className="md-input__message" role="alert">
                  {t('integrations.scopesValidationMessage')}
                </div>
              </div>
            </div>
          )}
          {existingIntegration && (
            <EditButtons
              show={isDirty('scopes')}
              handleCancel={(): void => {
                handleEditCancel('scopes')
              }}
              handleSave={async (): Promise<void> => {
                await handleFormSubmit('scopes')
              }}
              isLoading={isSubmitting}
              existingApplication={existingIntegration}
              disableSave={hasEmptyScopeError}
            />
          )}
        </div>
        <div className="columns medium-2" />
      </div>
      {existingIntegration ? (
        <div className="form-row btn-row-left">
          <div className="columns medium-3">
            <h3>{t('integrations.deleteIntegration')}</h3>
            <p>{t('integrations.deleteIntegrationLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <Button
              id="integration-delete-button"
              ariaLabel={t('integrations.deleteIntegration')}
              onClick={(): void =>
                setDialogState({ isShowing: true, type: 'delete' })
              }
              role="button"
              color="red"
              data-cy="delete-integration-btn"
              className={isSubmitting ? 'disabled' : ''}
            >
              {t('integrations.deleteIntegration')}
            </Button>
          </div>
          <div className="columns medium-2" />
        </div>
      ) : null}
      <div className="form-row btn-row">
        <div className="columns">
          <Checkbox
            className="tos-checkbox"
            value="agreement-checkbox"
            checked={acceptedToS || existingIntegration}
            htmlId={'acceptStatement'}
            onClick={(): void => {
              setacceptedToS(!acceptedToS)
            }}
            data-cy="tos-checkbox"
            disabled={existingIntegration}
          >
            <span
              onClick={handleToSLabelInteraction}
              onKeyDown={handleToSLabelInteraction}
              role="checkbox"
              aria-checked={acceptedToS}
              tabIndex={0}
              id="acceptance-label"
              data-cy="tos-checkbox-label"
              className={existingIntegration ? 'md-font-color--disabled' : ''}
            >
              {!existingIntegration
                ? t('integrations.acceptStatement')
                : t('integrations.acceptedStatement')}
              <a
                href="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('integrations.termsOfService')}
              </a>
              {t('integrations.andForCheckbox')}
              <Link
                to={
                  'https://www.cisco.com/c/en/us/about/legal/privacy-full.html'
                }
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('integrations.privacyPolicy')}
              </Link>
              .
            </span>
          </Checkbox>
          {!existingIntegration ? (
            <EditButtons
              show={true}
              handleCancel={handleCreateCancel}
              handleSave={handleFormSubmit}
              isLoading={isSubmitting}
              disableSave={disableFormSubmit}
            />
          ) : null}
        </div>
        <div className="columns medium-2" />
      </div>

      <ErrorAlert showAlert={hasError} handleClick={setHasError} />
      {renderDialog()}
    </div>
  )
}

export default Integration
